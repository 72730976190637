/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  ReactNode,
} from 'react';
import classNames from 'classnames';
import { usePathPrefix } from '../../../../constants/hooks';
import type { screens, platforms, Platform } from './types';
import root from '../../styles.module.scss';
import styles from './styles.module.scss';
import { CaseDevice, DeviceSettings } from '../CaseDevice';
import { CaseDesktop } from '../CaseDesktop';
import { AndroidIcon } from '../../../icons/AndroidIcon';
import { AppleIcon } from '../../../icons/AppleIcon';
import { t } from '../../../../i18n';
import { getFileNameFromPath } from '../../../../utils/getFileNameFromPath';
import { IconComponent } from '../../../common/Icon';
import AppStore from '../../../../images/LinkStore/AppStore.png';
import GooglePlay from '../../../../images/LinkStore/GooglePlay.png';
import { WebIcon } from '../../../icons/WebIcon';
import { CasePresentation } from '../CasePresentation';

export type buttonProps = {
  value: number;
  onChange: React.Dispatch<React.SetStateAction<number>>;
  current: number;
  variant: [string, string, string?, string?];
};

interface IProps {
  screens: screens;
  platforms: platforms;
  linkStore?: {
    iOS?: string;
    Android?: string;
  };
  resultParagraphs: string[];
  renderButton: (buttonProps: buttonProps) => ReactNode;
  colors: {
    primary: string;
    secondary: string;
  };
  presentation?: boolean;
}

const deviceSettings: Record<string, DeviceSettings> = {
  'iphone-xv': {
    width: 357,
    height: 729,
    screenWidth: 323,
    screenHeight: 699,
    x: 17,
    y: 15,
    zIndex: 0,
  },
  'iphone-x': {
    width: 375,
    height: 740,
    screenWidth: 320,
    screenHeight: 693,
    x: 27,
    y: 25,
    zIndex: 0,
  },
  'android-center-camera': {
    width: 366,
    height: 730,
    screenWidth: 316,
    screenHeight: 700,
    x: 25,
    y: 15,
    zIndex: 0,
  },
  android: {
    width: 375,
    height: 740,
    screenWidth: 320,
    screenHeight: 693,
    x: 27,
    y: 25,
    zIndex: 1,
  },
  'androidTablet-portrait': {
    width: 471,
    height: 700,
    screenWidth: 385,
    screenHeight: 525,
    x: 44,
    y: 86,
    zIndex: 1,
  },
};

const icons = {
  Android: AndroidIcon,
  iOS: AppleIcon,
  Desktop: WebIcon,
  iOSWithTabletMode: AppleIcon,
  AndroidWithTabletMode: AndroidIcon,
};

const platformNames: Record<keyof typeof Platform, string> = {
  Android: 'Android',
  iOS: 'iOS',
  Desktop: 'Desktop',
  iOSWithTabletMode: 'iOS',
  AndroidWithTabletMode: 'Android',
};

const CaseResult: FC<IProps> = ({
  screens,
  platforms,
  linkStore,
  resultParagraphs,
  renderButton,
  colors,
  presentation,
}) => {
  const prefix = usePathPrefix();

  const [variant, setVariant] = useState(0);
  const [platform, setPlatform] = useState<Platform>(
    Object.keys(screens)[0] as any
  );
  const [tabletMode, setTabletMode] = useState<number>(0);

  const variants = useMemo<[string, string, string?, string?][]>(
    () => screens[platform] || [],
    [prefix, platform, screens]
  );

  const images = useMemo(() => variants.map((v) => v[1]), [variants]);
  const tabletModeImages = useMemo(
    () => variants.map((v) => v[3]),
    [variants]
  ).filter((item) => item);

  const availablePlatforms = useMemo(
    () => Object.keys(screens).map((p: unknown) => platforms[p as Platform]),
    []
  );

  const switchToNextVariant = useCallback(
    () => setVariant(variant < variants.length - 1 ? variant + 1 : 0),
    [variant, setVariant, variants]
  );

  const switchToPreviousVariant = useCallback(
    () => setVariant(variant > 0 ? variant - 1 : variants.length - 1),
    [variant, setVariant, variants]
  );

  const switchPlatform = useCallback(
    () => setPlatform((prev) => (+prev ? 0 : 1)),
    []
  );

  useEffect(() => setVariant(0), [platform]);

  const activeDevice = platforms[platform][2];
  const activeDeviceName = getFileNameFromPath(activeDevice);
  const isDesktop = platforms[platform][0] === 'Desktop';

  const isiOSWithTabletMode = platforms[platform][0] === 'iOSWithTabletMode';
  const isAndroidWithTabletMode =
    platforms[platform][0] === 'AndroidWithTabletMode';
  const isTabletModeActive = tabletMode === 1;

  return (
    <div
      className={classNames(
        root.screen,
        // eslint-disable-next-line no-nested-ternary
        isDesktop
          ? styles.resultDesktop
          : isTabletModeActive
          ? styles.resultTablet
          : styles.result
      )}
    >
      <div
        className={classNames(
          isTabletModeActive && styles.contents,
          isDesktop ? styles.textResult : styles.text
        )}
      >
        <div
          className={classNames(
            (isiOSWithTabletMode || isAndroidWithTabletMode) &&
              styles.contentWrapper
          )}
        >
          <h2>{t('case.results')}</h2>

          {resultParagraphs.map((paragraph, i) => (
            // eslint-disable-next-line react/no-danger
            <p
              key={paragraph}
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          ))}
        </div>

        <div>
          {!isDesktop && (
            <div className={styles.arrows}>
              {availablePlatforms.length > 1 && (
                <div
                  className={classNames(styles.arrows__arrow)}
                  onClick={switchPlatform}
                >
                  <svg
                    width="12"
                    height="20"
                    viewBox="0 0 12 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.98811 0L12 2.01429L4.02378 10L12 17.9857L9.98811 20L0 10L9.98811 0Z"
                      fill={colors.primary}
                    />
                  </svg>
                </div>
              )}

              <div className={styles.platforms}>
                <div>
                  <IconComponent
                    name={availablePlatforms[platform][0]}
                    iconProps={{ color: colors.primary }}
                    icons={icons}
                  />
                  <span
                    style={{
                      color: colors.primary,
                    }}
                  >
                    {
                      platformNames[
                        platforms[platform][0] as keyof typeof Platform
                      ]
                    }
                  </span>
                </div>
              </div>

              {availablePlatforms.length > 1 && (
                <div className={styles.arrows__arrow} onClick={switchPlatform}>
                  <svg
                    width="12"
                    height="20"
                    viewBox="0 0 12 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.01189 20L-1.76094e-07 17.9857L7.97622 10L-1.57236e-06 2.01429L2.01189 8.73188e-07L12 10L2.01189 20Z"
                      fill={colors.primary}
                    />
                  </svg>
                </div>
              )}
            </div>
          )}

          {!isDesktop && (
            <div className={classNames(styles.platforms, styles.hidden)}>
              {availablePlatforms.map((p, i) => (
                <div
                  key={p[0]}
                  onClick={() => setPlatform(Object.keys(screens)[i] as any)}
                >
                  <IconComponent
                    name={p[0]}
                    icons={icons}
                    iconProps={{
                      color: +platform === i ? colors.primary : '',
                    }}
                  />
                  <span
                    style={{
                      color: +platform === i ? colors.primary : '',
                    }}
                  >
                    {platformNames[p[0] as keyof typeof Platform]}
                  </span>
                </div>
              ))}
            </div>
          )}

          {isDesktop && (
            <>
              <div className={styles.arrows}>
                <div
                  className={classNames(styles.arrows__arrow)}
                  onClick={switchToPreviousVariant}
                >
                  <svg
                    width="12"
                    height="20"
                    viewBox="0 0 12 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.98811 0L12 2.01429L4.02378 10L12 17.9857L9.98811 20L0 10L9.98811 0Z"
                      fill={colors.primary}
                    />
                  </svg>
                </div>

                <div
                  className={styles.arrows__value}
                  style={{ color: colors.primary }}
                >
                  {(variants[variant] && variants[variant][0]) || ''}
                </div>

                <div
                  className={styles.arrows__arrow}
                  onClick={switchToNextVariant}
                >
                  <svg
                    width="12"
                    height="20"
                    viewBox="0 0 12 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.01189 20L-1.76094e-07 17.9857L7.97622 10L-1.57236e-06 2.01429L2.01189 8.73188e-07L12 10L2.01189 20Z"
                      fill={colors.primary}
                    />
                  </svg>
                </div>
              </div>
              <p className={styles.description}>{variants[variant][2]}</p>
            </>
          )}

          {isDesktop ? (
            <div className={styles.desktopButtonsScreens}>
              <div className={styles.desktopButtons}>
                {variants.map((v, i) =>
                  renderButton({
                    value: i,
                    onChange: setVariant,
                    current: variant,
                    variant: v,
                  })
                )}
              </div>
              <div className={styles.deviceDesktop}>
                <CaseDesktop images={images} active={variant} />
              </div>
            </div>
          ) : (
            <div className={styles.buttons}>
              {variants.map((v, i) =>
                renderButton({
                  value: i,
                  onChange: setVariant,
                  current: variant,
                  variant: v,
                })
              )}
            </div>
          )}

          <div className={styles.store_link_desktop}>
            {linkStore?.iOS ? (
              <a href={linkStore.iOS} target="_blank" rel="noreferrer">
                <img src={AppStore} alt="" />
              </a>
            ) : (
              <></>
            )}
            {linkStore?.Android ? (
              <a href={linkStore.Android} target="_blank" rel="noreferrer">
                <img src={GooglePlay} alt="" />
              </a>
            ) : (
              <></>
            )}
          </div>

          {presentation && <CasePresentation />}
        </div>

        {!isDesktop && (
          <>
            <div className={styles.arrows}>
              <div
                className={classNames(styles.arrows__arrow)}
                onClick={switchToPreviousVariant}
              >
                <svg
                  width="12"
                  height="20"
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.98811 0L12 2.01429L4.02378 10L12 17.9857L9.98811 20L0 10L9.98811 0Z"
                    fill={colors.primary}
                  />
                </svg>
              </div>
              <div
                className={styles.arrows__value}
                style={{ color: colors.primary }}
              >
                {(variants[variant] && variants[variant][0]) || ''}
              </div>
              <div
                className={styles.arrows__arrow}
                onClick={switchToNextVariant}
              >
                <svg
                  width="12"
                  height="20"
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.01189 20L-1.76094e-07 17.9857L7.97622 10L-1.57236e-06 2.01429L2.01189 8.73188e-07L12 10L2.01189 20Z"
                    fill={colors.primary}
                  />
                </svg>
              </div>
            </div>
            <p
              className={styles.description}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: variants[variant][2] || '' }}
            />
          </>
        )}
      </div>

      {!isDesktop && (
        <div className={styles.device}>
          {isTabletModeActive ? (
            <CaseDesktop
              images={tabletModeImages as string[]}
              active={variant}
            />
          ) : (
            <CaseDevice
              images={images}
              activeDevice={platforms[platform][2]}
              active={variant}
              deviceSettings={deviceSettings[activeDeviceName ?? '']}
            />
          )}

          {(isiOSWithTabletMode || isAndroidWithTabletMode) && (
            <div className={styles.device_switcher_buttons_container}>
              <button
                type="button"
                className={classNames(
                  tabletMode !== 1 ? styles.button_switch_active : null
                )}
                onClick={() => setTabletMode(0)}
              >
                <img
                  src={`${prefix}/case/common/switcher-icons/phone.svg`}
                  alt="phone"
                />
                {t('case.result_button_phone')}
              </button>

              <button
                type="button"
                onClick={() => setTabletMode(1)}
                className={classNames(
                  isTabletModeActive ? styles.button_switch_active : null
                )}
              >
                <img
                  src={`${prefix}/case/common/switcher-icons/tablet.svg`}
                  alt="tablet"
                />
                {t('case.result_button_tablet')}
              </button>
            </div>
          )}

          <div className={styles.store_link_mobile}>
            {linkStore?.iOS ? (
              <a href={linkStore.iOS} target="_blank" rel="noreferrer">
                <img src={AppStore} alt="" />
              </a>
            ) : (
              <></>
            )}
            {linkStore?.Android ? (
              <a href={linkStore.Android} target="_blank" rel="noreferrer">
                <img src={GooglePlay} alt="" />
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export { CaseResult };
