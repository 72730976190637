import React, { FC, ReactNode } from 'react';
import styles from './styles.module.scss';
import { CaseInfo } from '../CaseInfo';
import { t } from '../../../../i18n';
import { ListSlider } from '../CaseSlider';

export type stack = {
  iconName?: string;
  text: string;
};

interface IProps {
  mobileList: stack[];
  backendList?: stack[];
  androidList?: stack[];
  iosList?: stack[];
  renderCard: (el: stack) => ReactNode;
  infoText: ReactNode;
}

const CaseStack: FC<IProps> = ({
  mobileList,
  backendList,
  androidList,
  iosList,
  renderCard,
  infoText,
}) => (
  <div className={styles.stack}>
    <h2>{t('case.technology')}</h2>
    {backendList && <p>Backend</p>}
    {backendList && <ListSlider arr={backendList} renderCard={renderCard} />}
    {backendList && <p>{t('case.mobile')}</p>}
    {androidList && (
      <p>
        <b>Android</b>
      </p>
    )}
    {androidList && <ListSlider arr={androidList} renderCard={renderCard} />}
    <div />
    {iosList && (
      <p>
        <b>iOS</b>
      </p>
    )}
    {iosList && <ListSlider arr={iosList} renderCard={renderCard} />}
    {mobileList && <ListSlider arr={mobileList} renderCard={renderCard} />}
    {infoText && <CaseInfo text={infoText} />}
  </div>
);

export { CaseStack };
