/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import styles from './styles.module.scss';
import { Slider } from '../../../common/Slider';
import { t } from '../../../../i18n';

export type feedback = {
  name: string;
  position: string;
  text: string;
  avatar?: string;
};

interface IProps {
  feedback: feedback[];
  buttonsColor: string;
}

const CaseFeedback: FC<IProps> = ({ feedback, buttonsColor }) => (
  <div className={styles.feedback}>
    <div className={styles.item}>
      <h2>{t('case.customer_feedback')}</h2>

      <Slider
        className={styles.slider}
        sliderProps={{
          container: `.${styles.slider}`,
          items: 1,
          mouseDrag: true,
          slideBy: 'page',
          gutter: 0,
          controls: true,
          prevButton: '#prev',
          nextButton: '#next',
          nav: false,
          center: true,
          lazyload: false,
          swipeAngle: 45,
          loop: true,
        }}
        feedbackCount={feedback.length}
        containerClassName={styles.pad}
        color={buttonsColor}
      >
        {feedback.map((el, i) => (
          <div className={styles.quote} key={i}>
            {(el.name || el.position) && (
              <div className={styles.quote__header}>
                <div className={styles.quote__name}>{el.name}</div>
                <div className={styles.quote__position}>{el.position}</div>
              </div>
            )}
            <div className={styles.quote__body}>{el.text}</div>
          </div>
        ))}
      </Slider>
    </div>
  </div>
);

export { CaseFeedback };
