/* eslint-disable no-shadow */
import React, { FC } from 'react';
import classNames from 'classnames';
import { usePathPrefix } from '../../../../../constants/hooks';
import root from '../../../styles.module.scss';
import styles from './styles.module.scss';
import { Breadcrumbs } from '../../../../common/Breadcrumbs';
import { CaseTitleTerm } from '../../CaseTitleTerm';
import { isTooDark } from '../../../../../utils/color';

interface IProps {
  title: string;
  subtitle: string;
  description: string;
  termsBackground: string;
  terms: {
    image: string;
    title: string;
    text: string;
  }[];
  afterTermsDescription?: string;
  mainBackroundColor: string;
}

const CaseTitleWithDeviceLeft: FC<IProps> = ({
  title,
  subtitle,
  description,
  terms,
  afterTermsDescription,
  termsBackground,
  mainBackroundColor,
}) => {
  const prefix = usePathPrefix();
  const isColorDark = isTooDark(mainBackroundColor);
  // TODO: Need added color props if added custom color on template
  const fontColor = isColorDark ? '#ffffff' : '#272727';

  return (
    <>
      <div className={classNames(root.breadcrumbs, styles.breadcrumbs)}>
        <Breadcrumbs title={title} textColor={fontColor} />
      </div>

      <h1 style={{ color: fontColor }}>{subtitle}</h1>

      <span style={{ color: fontColor }}>{description}</span>

      <div className={styles.break} />

      {terms &&
        terms.map(({ image, title, text }, i) => (
          <CaseTitleTerm
            key={title}
            image={`${prefix}${image}`}
            title={title}
            text={text}
            backgroundColor={termsBackground}
            textColor={fontColor}
          />
        ))}

      <span style={{ color: fontColor }}>{afterTermsDescription}</span>
    </>
  );
};

export { CaseTitleWithDeviceLeft };
